import React from "react";

function IconDownArrow(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      viewBox="0 0 40 40"
      {...props}
    >
      {/* <path
        stroke="none"
        d="M21.886 5.536A1.002 1.002 0 0021 5H3a1.002 1.002 0 00-.822 1.569l9 13a.998.998 0 001.644 0l9-13a.998.998 0 00.064-1.033zM12 17.243L4.908 7h14.184L12 17.243z"
      ></path> */}
            <path
        d="M37.367 14.437h-2.036L21.9 33.588 8.469 14.437H6.433l14.785 21.08a.831.831 0 001.364 0l14.785-21.08z"
        transform="translate(-8.318 -12.527) scale(1.29307)"
      ></path>
    </svg>
  );
}

export default IconDownArrow;
